import React from 'react';
import { navigate } from 'gatsby';
import Layout from '../../../components/287634/layout/layout';
import Button from '../../../components/287634/button/button';
import { H1, H3, H4, P } from '../../../components/287634/typography';

const WelcomePage = ({ search }: PageProps) => {
  return (
    <Layout>
      <H1 className="mx-5 mt-20 mb-4 text-center text-primary">
        <span>¡Hola!</span>
        <br />
        <span>
          Regístrate ahora y<br /> paga silbando.
        </span>
      </H1>
      <section className="flex flex-col items-center">
        <P className="mb-11 text-center text-primary">Tu cuenta en 4 simples pasos:</P>
        <ol className="neon-lime-disc list-none text-base font-medium text-primary">
          <li>
            <H3>Crea tu código de seguridad</H3>
          </li>
          <li>
            <H3>Fotografía tu documento</H3>
          </li>
          <li>
            <H3>Valida tu identidad</H3>
          </li>
          <li>
            <H3>Completa tus datos</H3>
          </li>
        </ol>
      </section>
      <section className="flex flex-col items-center">
        <H4 className="mb-4 text-gray">Podrás registrarte si tienes + 18 años de edad.</H4>
        <Button
          aria-label="Continuar"
          onClick={() => {
            navigate(`../dynamic-user${search}`);
          }}
        >
          Continuar
        </Button>
      </section>
    </Layout>
  );
};

export default WelcomePage;
